import "./App.css";
import styled from "styled-components";
import HyperLogo from "./Images/HyperLogo.png";
import SportBuffLogo from "./Images/Sport-Buff.png";
import { useEffect, useState } from "react";
import moment from "moment";
const Main = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
`;
const Engine = styled.iframe`
  position: absolute;
  left: 0;
  box-sizing: border-box;
  width: ${(props) => props.width + "px"};
  height: ${(props) => props.height + "px"};
  background-color: rgba(0, 0, 0, 0);
  z-index: -1;
  /* border-radius: 8px; */
  /* box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12); */
  border: none;
  overflow: hidden;
  margin: 5px;
  pointer-events: none;
  border: ${({ connected }) => (connected === true ? "2px solid #30c17e" : "")};
  border-radius: 4px;
  z-index: 1000;
`;

const VideoContainer = styled.div`
  min-width: 778px;
  min-height: 442px;
  position: relative;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

const Row = styled.div`
  display: flex;

  width: 100vw;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
`;
const Image = styled.img`
  margin: 10px;
`;

const StreamButton = styled.div`
  background-color: ${({ selected }) => (selected === true ? "#30c17e" : "")};
`;
const width = 768;
const height = 432;
let animated;
let prev_voteable;
function App() {
  const [streams, setStreams] = useState([]);
  const [selectedStream, setSelectedStream] = useState();
  useEffect(() => {
    fetch("https://api.playground.buffup.net/streams?pagination.pageSize=30")
      .then((res) => res.json())
      .then((data) => {
        setStreams(data.streams);
        setSelectedStream(data?.streams?.[0]);
      });
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      getData({ game_id: selectedStream?.gameIds?.[0] });
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [streams, selectedStream]);

  function getData({ game_id }) {
    if (game_id) {
      fetch(`https://api.playground.buffup.net/games/${game_id}/content`)
        .then((res) => res.json())
        .then((data) => {
          let voteable = data.content?.filter((c) => {
            let close_diff = moment(c.entity?.closesAt).diff(moment());
            return c.type === "VOTEABLE" && close_diff > 0;
          })?.[0]?.entity;
          if (voteable) {
            let diff = moment(voteable.opensAt).diff(moment());
            if (animated !== voteable.voteableId) {
              animated = voteable?.voteableId;
              prev_voteable = voteable;
              sendQuestion({
                language: "LANGUAGE_ENGLISH",
                scene: "Question",
                engine: window.engine_1,
                voteable: voteable,
              });
            }
          } else if (animated) {
            animated = null;
            sendAnswer({
              language: "LANGUAGE_ENGLISH",
              scene: "Question",
              engine: window.engine_1,
              voteable: prev_voteable,
            });
            setTimeout(() => {
              animateOut({ engine: window.engine_1, scene: "Question" });
            }, 5000);
          }
        });
    }
  }

  function animateOut({
    language = "LANGUAGE_ENGLISH",
    engine,
    voteable,
    scene,
  }) {
    window.ws.send(
      JSON.stringify({
        action: "animate",
        engine: engine,
        group: "615b41743adaf9b55067b902",
        data: {
          scene: scene,
          timeline: "OUT",
          data: [],
        },
      })
    );
  }

  function sendAnswer({
    language = "LANGUAGE_ENGLISH",
    engine,
    voteable,
    scene,
  }) {
    let correct_answer = voteable?.answers?.findIndex((a) => a.correct);
    window.ws.send(
      JSON.stringify({
        action: "animate",
        engine: engine,
        group: "615b41743adaf9b55067b902",
        data: {
          scene: scene,
          timeline: "Op" + (correct_answer + 1) + "-Answer",
          data: [],
        },
      })
    );
  }

  function sendQuestion({
    language = "LANGUAGE_ENGLISH",
    engine,
    voteable,
    scene,
  }) {
    window.ws.send(
      JSON.stringify({
        action: "animate",
        engine: engine,
        group: "615b41743adaf9b55067b902",
        data: {
          scene: scene,
          timeline: "IN",
          data: [
            {
              name: "QuestionText",
              value: {
                text: voteable.question?.localisations?.find(
                  (l) => l.localisation === language
                )?.text,
              },
            },
            {
              name: "Op1Image",
              value: {
                neverno_image: voteable.answers?.[0]?.localisations?.find(
                  (l) => l.localisation === language
                )?.imageUrl,
              },
            },
            {
              name: "Op1Text",
              value: {
                text: voteable.answers?.[0]?.localisations?.find(
                  (l) => l.localisation === language
                )?.text,
              },
            },
            {
              name: "Op2Image",
              value: {
                neverno_image: voteable.answers?.[1]?.localisations?.find(
                  (l) => l.localisation === language
                )?.imageUrl,
              },
            },
            {
              name: "Op2Text",
              value: {
                text: voteable.answers?.[1]?.localisations?.find(
                  (l) => l.localisation === language
                )?.text,
              },
            },
            {
              name: "Option3GRP",
              value: {
                visible: false,
              },
            },
            {
              name: "Option4GRP",
              value: {
                visible: false,
              },
            },
          ],
        },
      })
    );
  }

  return (
    <Main>
      <Header>
        <Image height="30" alt="Logo" src={HyperLogo} />
        <Image height="30" src={SportBuffLogo} alt="SportBuff Logo" />
      </Header>
      <Row style={{ height: "100vh" }}>
        <Row style={{ backgroundColor: "#131822" }}>
          <Column style={{ minWidth: "300px" }}>
            {streams?.map((stream) => {
              return (
                <StreamButton
                  selected={stream.id === selectedStream?.id}
                  onClick={() => {
                    setSelectedStream(stream);
                  }}
                >
                  {stream.title}
                </StreamButton>
              );
            })}
          </Column>
          <Column>
            <Row>
              <VideoContainer>
                <video
                  width={width}
                  height={height}
                  src="https://buffup-public.s3.eu-west-2.amazonaws.com/video/FIFA_VOD_SHORT.mp4"
                  controls
                  autoPlay
                  muted
                  style={{ position: "absolute", left: "0" }}
                />
                <Engine
                  width={width}
                  height={height}
                  id="RenderEngine"
                  title="Preview"
                  src={`https://create.hyper.live/live/${window.engine_1}/623afb8e57a633ff4545fa4b?width=${width}&height=${height}&command_server=wss://stage-command.hyper.live`}
                ></Engine>
              </VideoContainer>

              {/* <VideoContainer>
                <video
                  width={width}
                  height={height}
                  src="https://buffup-public.s3.eu-west-2.amazonaws.com/video/FIFA_VOD_SHORT.mp4"
                  controls
                  autoPlay
                  muted
                  style={{ position: "absolute", left: "0" }}
                />
                <Engine
                  width={width}
                  height={height}
                  id="RenderEngine"
                  title="Preview"
                  src={`https://create.hyper.live/live/${window.engine_2}/623afb8e57a633ff4545fa4b?width=${width}&height=${height}&command_server=wss://stage-command.hyper.live`}
                ></Engine>
              </VideoContainer> */}
            </Row>
            {/* <Row>
              <VideoContainer>
                <video
                  width={width}
                  height={height}
                  src="https://buffup-public.s3.eu-west-2.amazonaws.com/video/FIFA_VOD_SHORT.mp4"
                  controls
                  autoPlay
                  muted
                  style={{ position: "absolute", left: "0" }}
                />
                <Engine
                  width={width}
                  height={height}
                  id="RenderEngine"
                  title="Preview"
                  src={`https://create.hyper.live/live/${window.engine_3}/623afb8e57a633ff4545fa4b?width=${width}&height=${height}&command_server=wss://stage-command.hyper.live`}
                ></Engine>
              </VideoContainer>

              <VideoContainer>
                <video
                  width={width}
                  height={height}
                  src="https://buffup-public.s3.eu-west-2.amazonaws.com/video/FIFA_VOD_SHORT.mp4"
                  controls
                  autoPlay
                  muted
                  style={{ position: "absolute", left: "0" }}
                />
                <Engine
                  width={width}
                  height={height}
                  id="RenderEngine"
                  title="Preview"
                  src={`https://create.hyper.live/live/${window.engine_4}/623afb8e57a633ff4545fa4b?width=${width}&height=${height}&command_server=wss://stage-command.hyper.live`}
                ></Engine>
              </VideoContainer>
            </Row> */}
          </Column>
        </Row>
      </Row>
    </Main>
  );
}

export default App;
