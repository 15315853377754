import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import moment from "moment";
window.ws = new WebSocket("wss://stage-command.hyper.live");
window.engine_1 = Math.floor(Math.random() * 10000000).toString();
window.engine_2 = Math.floor(Math.random() * 10000000).toString();
window.engine_3 = Math.floor(Math.random() * 10000000).toString();
window.engine_4 = Math.floor(Math.random() * 10000000).toString();
window.ws.onopen = () => {
  console.log("connected");
};

let stream = {};
let voteables = {};
let current_time = moment();
let index = 0;
let prev_index = 0;

// fetch("https://api.playground.buffup.net/streams?pagination.pageSize=30")
//   .then((res) => res.json())
//   .then((data) => {
//     let streams = data.streams;
//     stream = streams?.find(
//       (s) => s.id === "0aa18c27-ccb8-4b69-abae-5720a99c11d7"
//     );

//     current_time = moment(stream.liveAt);
//     current_time = moment("2023-05-17T16:00:50.881Z");
//     fetch(
//       "https://api.playground.buffup.net/games/c236e2d2-d3b4-4ae8-8fa3-a43d8de5ebbe/content"
//     )
//       .then((res) => res.json())
//       .then((data) => {
//         voteables = data.content
//           ?.filter((c) => c.type === "VOTEABLE")
//           ?.map((c) => c.entity)
//           ?.sort((a, b) => {
//             return moment(a.opensAt) - moment(b.opensAt);
//           });
//         console.log(voteables[0]);
//         run();
//       });
//   });

function run() {
  const video = document.querySelector("video");
  if (video && video.currentTime > 0) {
    let voteable = voteables[index];
    let prev_voteable = {};

    prev_voteable = voteables[prev_index];

    if (current_time >= moment(voteable?.opensAt)) {
      console.log(current_time);
      index++;
      sendQuestion({
        language: "LANGUAGE_ENGLISH",
        scene: "Question",
        engine: window.engine_1,
        voteable,
      });
      sendQuestion({
        language: "LANGUAGE_SPANISH",
        scene: "Question",
        engine: window.engine_2,
        voteable,
      });
      sendQuestion({
        language: "LANGUAGE_ENGLISH",
        scene: "L3_Question",
        engine: window.engine_3,
        voteable,
      });
      sendQuestion({
        language: "LANGUAGE_SPANISH",
        scene: "L3_Question",
        engine: window.engine_4,
        voteable,
      });
    }
    if (current_time >= moment(prev_voteable.closesAt)) {
      prev_index++;
      sendAnswer({
        language: "LANGUAGE_SPANISH",
        scene: "Question",
        engine: window.engine_1,
        voteable: prev_voteable,
      });
      sendAnswer({
        language: "LANGUAGE_SPANISH",
        scene: "Question",
        engine: window.engine_2,
        voteable: prev_voteable,
      });
      sendAnswer({
        language: "LANGUAGE_SPANISH",
        scene: "L3_Question",
        engine: window.engine_3,
        voteable: prev_voteable,
      });
      sendAnswer({
        language: "LANGUAGE_SPANISH",
        scene: "L3_Question",
        engine: window.engine_4,
        voteable: prev_voteable,
      });
      setTimeout(() => {
        animateOut({ engine: window.engine_1, scene: "Question" });
        animateOut({ engine: window.engine_2, scene: "Question" });
        animateOut({ engine: window.engine_3, scene: "L3_Question" });
        animateOut({ engine: window.engine_4, scene: "L3_Question" });
      }, 5000);
    }
  }
  setTimeout(() => {
    current_time.add(100, "milliseconds");
    run();
  }, 100);
}

function animateOut({
  language = "LANGUAGE_ENGLISH",
  engine,
  voteable,
  scene,
}) {
  window.ws.send(
    JSON.stringify({
      action: "animate",
      engine: engine,
      group: "615b41743adaf9b55067b902",
      data: {
        scene: scene,
        timeline: "OUT",
        data: [],
      },
    })
  );
}
function sendAnswer({
  language = "LANGUAGE_ENGLISH",
  engine,
  voteable,
  scene,
}) {
  let correct_answer = voteable?.answers?.findIndex((a) => a.correct);
  window.ws.send(
    JSON.stringify({
      action: "animate",
      engine: engine,
      group: "615b41743adaf9b55067b902",
      data: {
        scene: scene,
        timeline: "Op" + (correct_answer + 1) + "-Answer",
        data: [],
      },
    })
  );
}
function sendQuestion({
  language = "LANGUAGE_ENGLISH",
  engine,
  voteable,
  scene,
}) {
  window.ws.send(
    JSON.stringify({
      action: "animate",
      engine: engine,
      group: "615b41743adaf9b55067b902",
      data: {
        scene: scene,
        timeline: "IN",
        data: [
          {
            name: "QuestionText",
            value: {
              text: voteable.question?.localisations?.find(
                (l) => l.localisation === language
              )?.text,
            },
          },
          {
            name: "Op1Image",
            value: {
              neverno_image: voteable.answers?.[0]?.localisations?.find(
                (l) => l.localisation === language
              )?.imageUrl,
            },
          },
          {
            name: "Op1Text",
            value: {
              text: voteable.answers?.[0]?.localisations?.find(
                (l) => l.localisation === language
              )?.text,
            },
          },
          {
            name: "Op2Image",
            value: {
              neverno_image: voteable.answers?.[1]?.localisations?.find(
                (l) => l.localisation === language
              )?.imageUrl,
            },
          },
          {
            name: "Op2Text",
            value: {
              text: voteable.answers?.[1]?.localisations?.find(
                (l) => l.localisation === language
              )?.text,
            },
          },
          {
            name: "Option3GRP",
            value: {
              visible: false,
            },
          },
          {
            name: "Option4GRP",
            value: {
              visible: false,
            },
          },
        ],
      },
    })
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
